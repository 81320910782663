<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <iframe
      class="wrap"
      :src="pSrc"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      pSrc: '',
      pdfUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      token: 'token'
    })
  },
  mounted () {
    this.pdfUrl = this.$route.query.pdfUrl
    this.loadPDF()
  },
  methods: {
    loadPDF () {
      // baseurl ：pdf存放的文件路径，可以是本地的，也可以是远程，这个是远程的，亲测可以用
      // const baseurl = 'http://image.cache.timepack.cn/nodejs.pdf'
      const baseurl = this.pdfUrl
      // ie有缓存加个随机数解决 + '?r=' + new Date()
      const pSrc = baseurl + '?r=' + new Date()
      this.pSrc = 'https://jscss.winshangdata.com/pdf/web/viewer.html?file=' + encodeURIComponent(pSrc) + '.pdf'
      // this.pSrc = '../../pdf/web/viewer.html?file=' + encodeURIComponent(pSrc) + '.pdf'
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.wrap
  position absolute
  border none
</style>
